<template>
  <div class="body">
    <v-header :headerClassName="headerClassName"></v-header>
    <div class="paper-body-index">
      <div class="banner">
        <img style="" :src="tempBgPath" alt />
          <span class="btn" @click="goRegistered" >
            马上注册
          </span>
      </div>
      <div class="download-content" v-show="isShow">
        <div class="notice">
          <!--<p class="font-gray"></p>-->
          <p class="font-gray">
            游客用户提交下载后，仅为您保留文件<em>48</em>小时，请尽快下载。
          </p>
          <p class="font-gray">
            注册成为淘个图官网用户，享受VIP价：1.0元/张，充值限时赠送,<em>低至0.5元/张!</em>
            （限前100位用户，先到先得，<a @click="goRegistered">马上注册</a>）
          </p>
          <!--<p class="font-gray">
            首批种子用户享受VIP价：1.0元/张（限前100位用户，先到先得，<a @click="goRegistered">马上注册</a>）
          </p>-->
          <div class="text-list">
            <p>
              <span class="circle"></span>
              <countdown
                      v-if="this.info.isFlag"
                      :currentTime="Number(info.currentTime)"
                      :startTime="Number(info.startDate)"
                      :endTime="Number(info.endDate)"
                      :tipText="'下载开始倒计时'"
                      :tipTextEnd="'下载结束倒计时'"
                      :endText="'次卡使用已过期'"
                      :dayTxt="'天'"
                      :hourTxt="'时'"
                      :minutesTxt="'分'"
                      :secondsTxt="'秒'"
                      ></countdown>
              <span v-else>未开始下载计时</span>
            </p>
            <p>
              <span class="circle"></span>
              <span :class="timecount == 0 ? 'font-gray': null">剩余下载次数: {{timecount}} 次</span>
            </p>
            <p>
              <span class="circle"></span>
              <span>部分海各素材创意223开头10位数ID暂时不支持下载，请您联系客服处理。</span>
            </p>
          </div>
        </div>
        <Content
          v-for="(item,index) in list"
          :key="index"
          v-if="index == current"
          :tabContent="item"
          :intimes="timecount"
          :isSearching="isSearching"
          @search="searchId"
        />
        <Table :data="tableDatas" :sureflag="isSureDownload" :downflag="isDownloading" :clearflag="isClearFlag" v-if="showTable" v-loading="tableLoading" @changeTime="changeTimes"
               @noBanlance="noBanlances" @onDownload="downloadId" @onSureDown="changeDown"/>
        <el-tabs v-model="currentValue" class="tabs-list">
          <el-tab-pane
                v-for="(o,oIndex) in tabList"
                :key="oIndex"
                :label="o.label"
                :name="o.name"
                class="tabs-content"
                v-loading="tableLoading1">
            <downlists :obj="o.data" @sizeChange="sizeChange" @pageChange="pageChange"/>
          </el-tab-pane>
        </el-tabs>
        <div class="qrCode">
          <p>通过微信联系我们</p>
          <img src="@/assets/images/code.jpg" alt width="100px"/>
          <p class="font-red">扫一扫关注官方微信公众号</p>
        </div>
      </div>
    </div>
    <Dialog :dialogVisible="dialogTableVisible" @closeDialog="closeConstDetailPop" />
  </div>
</template>

<script>
import header from "@/components/header/header.vue";
import countdown from '@/components/tempcount/countdown.vue';
import Table from "./module/table.vue";
import downlists from "./module/downlists.vue";
import Content from "./module/content.vue";
import Dialog from "./module/dialog.vue";
import { tempLogin,searchImage } from "../../api/downLoad";
import { message } from '@/utils/resetMessage';
import { setToken } from "../../utils/storage";
import { getConsumeList } from "../../api/order";
export default {
  components: {
    "v-header": header,
    Table,
    Content,
    Dialog,
    countdown,
    downlists
  },
  props:['tempToken'],
  data() {
    return {
      isClearFlag:false,
      isShow:false,
      isSearching:false,
      searchCount:0,
      isDownloading:false,
      isTip:'',
      headerClassName: "fixed-index header-opacity",
      current: 0,
      webType: "shutterstock",
      list: [{
        title: "【ss海各下载区】",
        subTitle: "",
        titleState: "success",
        label: ''
      }],
      tableDatas: [],
      showTable: false, //是否显示表格
      tableLoading: false, //加载数据效果
      dialogTableVisible: false, //费用明细弹窗
      isSureDownload:false,
      form: {
        webType: "",
        imageID: ""
      },
      token:this.tempToken,
      bannerList: [
        "24 hours",
        "自助下载系统 全新上线",
        "七大素材网站 合而为一 24小时下载服务"
      ],
      timecount: 0, //剩余次数
      time: 48, //图片保留时间
      dataForm:{
        img: '', //缩略图
        id: '', //图片ID
        imgSize: "", //图片大小
        complete: false, //操作状态为按钮
        loading: false, //操作状态为加载
        saleType: "", //默认为空
        price: 0, //图片价格
        balance: "", //用户余额
        value: 4, //图片类型默认值
        disabled: false, //是否可选
        options: [], //图片类型
        webType: this.webType, //网站类型
        downurl: "", //主下载链接
        downurl2: "", //备用下载链接
        type : true, //搜索ID是否正常
        msg:'', //提示消息
        error:false //下载错误状态
      },
      info:{
        currentTime:'',
        startDate:'',
        endDate:'',
        isFlag:false //是否显示
      },
      currentValue: "first",
      tabList: [
        {
          label: "Download History",
          name: "first",
          data: {}
        }
      ],
      tableLoading1: false,
      pageNo:1,
      pageSize:10,
      currentItem:'',
      tempBgPath: require("@/assets/images/temp_bg.png"),
    };
  },
  mounted() {
      this.$store.commit('loginNameType', null);
      this.$store.dispatch("setUserInfo", null);
      //this.$store.dispatch("userId", null);
      this.$store.commit("token", null);
      setToken(null);
      this.checkToken();
  },
  methods: {
    checkToken(){
      let dataform = {
        token:this.token
      }
      tempLogin(dataform).then(result => {
        console.log(JSON.stringify(result));
        if(result.flag){ //成功
          this.isShow = true;
          this.$store.commit("token", result.token);
          setToken(result.token);
          this.timecount = result.times;
          //初始化次卡
          this.getTableLists();
          if(result.status == 0){
            this.info.currentTime = new Date();
            this.info.startDate = result.flyTempAccountInfo.lastDownloadTime;
            this.info.endDate = result.date;
            this.info.isFlag = true;
          }else{
            this.info.isFlag = false;
          }
        }else{
          this.isShow = false;
          message.error({
            message: '该次卡已过期或无效',
            duration: 4000,
            offset: 80
          });
        }
      }).catch(error => {
        this.checkToken();
      });
    },
    getTableLists() {
      this.tableLoading1 = true;
      this.tabList.forEach((item, index) => {
        let dataform = {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          orderType: index + 1
        };
        getConsumeList(dataform).then(result => {
          console.log(JSON.stringify(result));
          this.tableLoading1 = false;
          item.data = result;
        }).catch(error => {
          this.getTableLists();
        });
      });
    },
    pageChange(params){
      this.pageNo = params;
      this.pageSize = 10;
      this.getTableLists();
    },
    sizeChange(param){
      this.pageSize = param;
      this.pageNo = 1;
      this.getTableLists();
    },
    //切换tab
    changeTab(index) {
      this.current = index;
    },
    //费用明细
    openConstDetailPop() {
      this.dialogTableVisible = true;
    },
    closeConstDetailPop() {
      this.dialogTableVisible = false;
    },
    //获取表格数据
    getTableDatas(result) {
      //请求成功后赋值，并且去掉加载效果
      this.dataForm = {
        img: '', //缩略图
        id: '', //图片ID
        imgSize: "", //图片大小
        complete: false, //操作状态为按钮
        loading: false, //操作状态为加载
        saleType: "", //默认为空
        price: 0, //图片价格
        balance: "", //用户余额
        value: 4, //图片类型默认值
        disabled: false, //是否可选
        options: [], //图片类型
        webType: this.webType, //网站类型
        downurl: "", //主下载链接
        downurl2: "", //备用下载链接
        type : true, //正常
        msg:'' //提示消息
      }
      //请求成功后赋值，并且去掉加载效果
      if(result.status==0){
        this.dataForm.img = result.iconUrl;
        this.dataForm.id = result.recCode;
        this.dataForm.price = result.price;
        if (result.searchDTO.picType == 0) { //0为jpg
          this.dataForm.imgSize = result.detailJson[0].px;
          this.dataForm.value = 0;
          this.dataForm.options = [
            {value: 0, label: "JPG"}
          ];
        } else {//4为eps
          this.dataForm.value = 4;
          this.dataForm.options = [
            {value: 4, label: "EPS"}
          ];
        }
      }else{
        this.dataForm.id = result.recCode;
        this.dataForm.msg = result.msg;
        this.dataForm.type = false;
      }
      this.tableDatas.push(this.dataForm);
      this.tableLoading = false;
    },
    //下载ID
    downloadId(flag){
      this.isSearching = flag;
      this.isDownloading = flag;
    },
    //改变下载状态
    changeDown(flag){
      this.isSureDownload = flag;
    },
    //搜索
    async searchId(prams) {
      this.isSearching =  true;
      this.isDownloading = true;
      this.isSureDownload = false;
      this.tableDatas = [];
      this.showTable = true;
      this.tableDatas.length > 0 ? (this.tableLoading = false) : (this.tableLoading = true);
      this.tableLoading = true;

      //根据余额限制搜索数量
      if(prams.length>this.timecount){//限制一次最多搜索timecount个
        message.error({
          message: '一次性最多只能搜索'+this.timecount+'个ID',
          showClose: true,
          duration: 4000,
          offset: 100
        });
        this.downloadId(false);
        this.showTable=false;
        this.tableLoading=false; //加载数据效果
        return;
      }else  { // 不超出不做限制

      }
      var len = 0;
      while (len < prams.length) {
        let dataform = {
          webType: this.webType,
          imageID: prams[len]
        };
        let res = await searchImage(dataform);
        if(res.recCode !=null && res.recCode != '') {
          this.getTableDatas(res);
        }else{
          message.error({
            message: 'Network exception, please try again',
            showClose: true,
            duration: 4000,
            offset: 100
          });
        }
        this.tableDatas.length > 0 ? (this.tableLoading = false) : (this.tableLoading = true);
        len++;
      }
      this.isSearching = false;
      this.isDownloading = false;
      this.isClearFlag = false;
      this.tableLoading = false;
    },
    //购买结束
    changeTimes(prams){
      this.timecount = prams;
    },
    noBanlances(){//余额不足
      this.tableDatas = [];
      this.isSureDownload = false;
    },
    //马上体验
    goLogin() {
      let routeUrl = this.$router.resolve({ path: "/login" });
      window.open(routeUrl.href, "_blank");
    },
    //马上注册
    goRegistered() {
      let routeUrl = this.$router.resolve({ path: "/registered" });
      window.open(routeUrl.href, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
.header-wrap {
  // box-shadow: 0 4px 10px 0 transparent;
}
.body {
  min-height: 100vh;
}
.paper-body-index {
  padding: 70px 0 10px;
  margin: 0 auto;
}
.banner {
  position:relative;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 399px;
  color: #000;
  border: 1px solid #e5e5e5;
  /*background-image: url("../../assets/images/temp_bg.png");*/
  /*background-size: cover;*/
  //background: #ffe9c0;
  .text1,
  .text2 {
    font-family: impact;
  }
  .text1 {
    font-size: 68px;
  }
  .text2 {
    // line-height: 50px;
    font-size: 28px;
  }
  .text3 {
    line-height: 38px;
    font-size: 20px;
    color: #ff9235;
  }
  .btn {
    /*margin-top: 10px;*/
    /*background: #ff9235;*/
    /*border: 1px solid #fe8925;*/
    /*&:hover {*/
    /*  opacity: 0.8;*/
    /*}*/
    position:absolute;left:57.5%;bottom:22.5%;
    width: 200px;
    height: 48px;
    background: #0773fc;
    box-shadow: 0 10px 10px 0 rgba(7, 115, 252, 0.2);
    border-radius: 28px;
    text-align: center;
    line-height: 48px;
    font-weight: 700;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    &:hover {
      color: #fff;
      background: rgba(7, 115, 252, 0.75);
    }
  }
}
.download-content {
  width: 1220px;
  margin: 0 auto;
}
.notice {
  padding: 0 10px 10px;
  em {
    color: red;
  }
  a {
    cursor: pointer;
  }
  p {
    line-height: 22px;
  }
  .text-list {
    margin-top: 5px;
    p {
      display: flex;
      align-content: center;
      align-items: center;
    }
  }
  .circle {
    margin-right: 6px;
    display: block;
    width: 8px;
    height: 8px;
    background: #0773fc;
    border-radius: 50%;
  }
}
.font-gray {
  color: #999;
}
.qrCode {
  margin: 50px 30px 30px 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  .font-red {
    line-height: 30px;
    font-weight: 700;
    color: red;
  }
}
</style>
